<template>
  <ul
    class="navbar-nav bg-gradient-white sidebar sidebar-light accordion toggled"
    id="accordionSidebar"
  >
    <!-- Sidebar - Brand -->
    <router-link
      class="sidebar-brand d-flex align-items-center justify-content-center"
      to="/"
      exact-active-class="no-active"
    >
      <img src="@/assets/uploads/SIGLE-MANAGEO.svg" class="p-4" alt="Manageo" />
    </router-link>

    <!-- Divider -->
    <hr class="sidebar-divider my-0" />

    <!-- Nav Item - Dashboard -->
    <li class="nav-item active">
      <router-link class="nav-link" to="/">
        <i class="fas fa-fw fa-plus"></i>
        <span>Nouvelle Vente</span>
      </router-link>
    </li>
    <hr class="sidebar-divider" />
    <!-- Nav Item - Pages Collapse Menu -->
    <li class="nav-item active">
      <router-link  class="nav-link" to="/receipts">
        <!-- <i class="fas fa-receipt"></i> -->
        <i class="fas fa-cart-arrow-down"></i>
        <span>Ventes</span>
      </router-link>
    </li>
    <!-- Nav Item - Pages Collapse Menu -->
    <li class="nav-item active">
      <router-link active-class="bg-light" class="nav-link" to="/customers">
        <i class="fas fa-users"></i>
        <span>Clients</span>
      </router-link>
    </li>
    <!-- Divider -->
    <hr class="sidebar-divider" />
    <li class="nav-item active">
      <router-link
        data-target="#logoutModal"
        class="nav-link btn btn-default"
        to="/cashier"
      >
        <i class="fas fas fa-cash-register fa-sm fa-fw"></i>
        <span>Caisse</span>
      </router-link>
    </li>
    <li class="nav-item active">
      <router-link
        class="nav-link"
        to="/statistics"
      >
        <i class="fas fa-chart-bar"></i>
        <span>Statistiques</span>
      </router-link>
    </li>
    <hr class="sidebar-divider" />
    <li class="nav-item active">
      <router-link
        data-target="#logoutModal"
        class="nav-link btn btn-default"
        to="/settings"
      >
        <i class="fas fa-cogs fa-sm fa-fw"></i>
        <span>Réglages</span>
      </router-link>
    </li>
    <!-- Divider -->
    <hr class="sidebar-divider" />
    <li class="nav-item active">
      <a
        @click.prevent="logOut"
        data-target="#logoutModal"
        class="nav-link btn btn-default"
      >
        <i class="fas fa-sign-out-alt fa-sm fa-fw"></i>
        <span>Se déconnecter</span></a
      >
    </li>
  </ul>
</template>

<script>
import { mapActions, mapMutations } from "vuex";

export default {
  methods: {
    ...mapActions("auth",["logout"]),
    ...mapMutations("auth",{
      showLoading: "loadSpinner",
    }),
    logOut() {
      this.showLoading(true);
      setTimeout(() => {
      this.logout({ router: this.$router });
        this.showLoading(false);
      }, 2000);
    },
  },
};
</script>
<style scoped>
img {
  width: 120px;
}
.sidebar {
  color: black !important;
}
.home-li:active {
  background: lightblue;
}
li.nav-item a i {
  font-size: 1.6rem !important;
}
li.nav-item a span {
  font-size: 13px !important;
}
</style>
