<template>
    <div id="wrapper">
        <app-sidebar></app-sidebar>
        <div id="content-wrapper" class="d-flex flex-column">
            <div id="content">
                <router-view/>
            </div>
            <app-footer></app-footer>
        </div>
    </div>    
</template>

<script>
import { mapGetters } from 'vuex';
import AppSidebar from '@/components/template/Sidebar';
import AppNavbar from '@/components/template/Navbar';
import AppFooter from '@/components/template/Footer';

export default {
    name: 'AdminLayout',
    components: {
        AppSidebar,
        AppNavbar,
        AppFooter,
    },
    computed:{
        ...mapGetters('auth',{
            token:'getUserToken'
        })
    }
}
</script>
<style>
.table-card {
  background: white;
  border: none !important;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
}
.global-table th {
  border: none;
  font-weight: 500;
}
</style>