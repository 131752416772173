<template>
  <footer class="sticky-footer">
    <div class="container my-auto">
      <div class="copyright text-center my-auto">
        <span>Copyright &copy; Manageo {{currentYear}}</span>
      </div>
    </div>
  </footer>
</template>
<script>
import moment from 'moment';

export default {
  computed: {
    currentYear() {
      return moment().format("YYYY");
    },
  },
};
</script>

<style scoped>
footer {
  padding:15px !important;
}
</style>
